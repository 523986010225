<template>
  <div class="mt-5 text-center">
    <div class="row">
      <h2 class="heading-section">Neem een kijkje</h2>
    </div>
    <hr class="featurette-divider col-md-10 col-lg-10 mx-auto mt-5" />
    <div class="row mx-5" id="gallery" data-bs-toggle="modal" data-bs-target="#imageGallery">
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/DSC_0787.jpeg"
          style="max-height: 800px"
          data-bs-target="#galleryCarousel"
          data-bs-slide-to="0"
        />
      </div>
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/DSC_0824.jpeg"
          style="max-height: 800px"
          data-bs-target="#galleryCarousel"
          data-bs-slide-to="1"
        />
      </div>
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/DSC_0809.jpeg"
          style="max-height: 800px"
          data-bs-target="#galleryCarousel"
          data-bs-slide-to="2"
        />
      </div>
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/DSC_0811.jpeg"
          style="max-height: 800px"
          data-bs-target="#galleryCarousel"
          data-bs-slide-to="3"
        />
      </div>
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/DSC_0814.jpeg"
          style="max-height: 800px"
          data-bs-target="#galleryCarousel"
          data-bs-slide-to="4"
        />
      </div>
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/DSC_0818.jpeg"
          style="max-height: 800px"
          data-bs-target="#galleryCarousel"
          data-bs-slide-to="5"
        />
      </div>
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/Slaapkamerschuur.jpg"
          style="max-height: 800px"
          data-bs-target="#galleryCarousel"
          data-bs-slide-to="6"
        />
      </div>
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/slaapkamerschuur2.jpg"
          style="max-height: 800px"
          data-bs-target="#galleryCarousel"
          data-bs-slide-to="7"
        />
      </div>
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/slaapkamerschuur3.jpg"
          style="max-height: 800px"
          data-bs-target="#galleryCarousel"
          data-bs-slide-to="8"
        />
      </div>
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/slaapkamerschuur4.jpg"
          style="max-height: 800px"
          data-bs-target="#galleryCarousel"
          data-bs-slide-to="9"
        />
      </div>
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/DSC_0793.jpeg"
          style="max-height: 800px"
          data-bs-target="#galleryCarousel"
          data-bs-slide-to="10"
        />
      </div>
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/DSC_0794.jpeg"
          style="max-height: 800px"
          data-bs-target="#galleryCarousel"
          data-bs-slide-to="11"
        />
      </div>
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/DSC_0796.jpeg"
          style="max-height: 800px"
          data-bs-target="#galleryCarousel"
          data-bs-slide-to="12"
        />
      </div>
    </div>
  </div>
  <div class="mt-5 text-center">
    <div
      class="row mx-5 d-flex justify-content-center"
      id="galleryBadkamer"
      data-bs-toggle="modal"
      data-bs-target="#imageGalleryBadkamer"
    >
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/badkamer1.jpg"
          style="max-height: 800px"
          data-bs-target="#galleryCarouselBadkamer"
          data-bs-slide-to="0"
        />
      </div>
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/badkamer2.jpg"
          style="max-height: 800px"
          data-bs-target="#galleryCarouselBadkamer"
          data-bs-slide-to="1"
        />
      </div>
      <div class="col-md-4 col-xxl-3 col-sm-6 mb-3">
        <img
          class="w-100 zooming-image"
          src="../assets/badkamer3.jpg"
          style="max-height: 800px"
          data-bs-target="#galleryCarouselBadkamer"
          data-bs-slide-to="2"
        />
      </div>
    </div>
  </div>

  <div class="modal fade" id="imageGallery" aria-hidden="true" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div id="galleryCarousel" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#galleryCarousel"
              data-bs-slide-to="0"
              class="active"
              aria-label="Slide 1"
              aria-current="true"
            ></button>
            <button type="button" data-bs-target="#galleryCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#galleryCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#galleryCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
            <button type="button" data-bs-target="#galleryCarousel" data-bs-slide-to="4" aria-label="Slide 5"></button>
            <button type="button" data-bs-target="#galleryCarousel" data-bs-slide-to="5" aria-label="Slide 6"></button>
            <button type="button" data-bs-target="#galleryCarousel" data-bs-slide-to="6" aria-label="Slide 7"></button>
            <button type="button" data-bs-target="#galleryCarousel" data-bs-slide-to="7" aria-label="Slide 8"></button>
            <button type="button" data-bs-target="#galleryCarousel" data-bs-slide-to="8" aria-label="Slide 9"></button>
            <button type="button" data-bs-target="#galleryCarousel" data-bs-slide-to="9" aria-label="Slide 10"></button>
            <button type="button" data-bs-target="#galleryCarousel" data-bs-slide-to="10" aria-label="Slide 11"></button>
            <button type="button" data-bs-target="#galleryCarousel" data-bs-slide-to="11" aria-label="Slide 12"></button>
            <button type="button" data-bs-target="#galleryCarousel" data-bs-slide-to="12" aria-label="Slide 13"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item">
              <img class="bd-placeholder-img d-block w-100" src="../assets/DSC_0787.jpeg" style="max-height: 800px" />
            </div>
            <div class="carousel-item">
              <img class="bd-placeholder-img d-block w-100" src="../assets/DSC_0824.jpeg" style="max-height: 800px" />
            </div>
            <div class="carousel-item">
              <img class="bd-placeholder-img d-block w-100" src="../assets/DSC_0809.jpeg" style="max-height: 800px" />
            </div>
            <div class="carousel-item">
              <img class="bd-placeholder-img d-block w-100" src="../assets/DSC_0811.jpeg" style="max-height: 800px" />
            </div>
            <div class="carousel-item">
              <img class="bd-placeholder-img d-block w-100" src="../assets/DSC_0814.jpeg" style="max-height: 800px" />
            </div>
            <div class="carousel-item">
              <img class="bd-placeholder-img d-block w-100" src="../assets/DSC_0818.jpeg" style="max-height: 800px" />
            </div>
            <div class="carousel-item">
              <img class="bd-placeholder-img d-block w-100" src="../assets/Slaapkamerschuur.jpg" style="max-height: 800px" />
            </div>
            <div class="carousel-item">
              <img class="bd-placeholder-img d-block w-100" src="../assets/slaapkamerschuur2.jpg" style="max-height: 800px" />
            </div>
            <div class="carousel-item">
              <img class="bd-placeholder-img d-block w-100" src="../assets/slaapkamerschuur3.jpg" style="max-height: 800px" />
            </div>
            <div class="carousel-item">
              <img class="bd-placeholder-img d-block w-100" src="../assets/slaapkamerschuur4.jpg" style="max-height: 800px" />
            </div>
            <div class="carousel-item active">
              <img class="bd-placeholder-img d-block w-100" src="../assets/DSC_0793.jpeg" style="max-height: 800px" />
            </div>
            <div class="carousel-item">
              <img class="bd-placeholder-img d-block w-100" src="../assets/DSC_0794.jpeg" style="max-height: 800px" />
            </div>
            <div class="carousel-item">
              <img class="bd-placeholder-img d-block w-100" src="../assets/DSC_0796.jpeg" style="max-height: 800px" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#galleryCarousel" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#galleryCarousel" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
          </button>
        </div>
        <div class="modal-footer">
          <button class="btn btn-dark" data-bs-dismiss="modal">Sluiten</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="imageGalleryBadkamer" aria-hidden="true" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div id="galleryCarouselBadkamer" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#galleryCarouselBadkamer"
              data-bs-slide-to="0"
              class="active"
              aria-label="Slide 1"
              aria-current="true"
            ></button>
            <button type="button" data-bs-target="#galleryCarouselBadkamer" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#galleryCarouselBadkamer" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item">
              <img class="bd-placeholder-img d-block w-100" src="../assets/badkamer1.jpg" style="max-height: 800px" />
            </div>
            <div class="carousel-item active">
              <img class="bd-placeholder-img d-block w-100" src="../assets/badkamer2.jpg" style="max-height: 800px" />
            </div>
            <div class="carousel-item">
              <img class="bd-placeholder-img d-block w-100" src="../assets/badkamer3.jpg" style="max-height: 800px" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#galleryCarouselBadkamer" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#galleryCarouselBadkamer" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
          </button>
        </div>
        <div class="modal-footer">
          <button class="btn btn-dark" data-bs-dismiss="modal">Sluiten</button>
        </div>
      </div>
    </div>
  </div>
</template>
